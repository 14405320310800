<template>
    <div class="mainHolder">
        <div class="headerHolder">
            <h2 id="superHeader">Kdo jsme?</h2>
        </div>
        <div class="AboutUsHolder L bb">
            <div>
                <h1>Malinká Gruzie v česku?</h1>
                <p>Naše <span>mladá firma</span>, kterou jsme založili jako dva nadšení studenti vysoké školy, se specializuje na prodej <span>výjimečných čajů dovážených přímo z Gruzie</span>. 
                Tato země, známá svou bohatou čajovou tradicí a jedinečnými přírodními podmínkami, nám poskytuje možnost nabízet čaje, které vynikají svou <span>kvalitou, chutí a aromatem</span>. 
                Naší vizí je přinést českým zákazníkům <span>kousek Gruzie v každém šálku</span>, propojit jejich každodenní rituály s jedinečnými chutěmi kavkazských hor a údolí.
                Díky přímé spolupráci s <span>malými gruzínskými farmáři</span> zajišťujeme, že každý čaj, který se dostane na váš stůl, je nejen autentický, ale také vysoce kvalitní a čerstvý.
                S láskou k tradici a respektem k přírodě vám přinášíme čaje, které vás nadchnou a zároveň <span>podpoří malé rodinné podniky v Gruzii.</span>
                </p>
            </div>
            <img src="@/assets/caj_o_nas.png">
        </div>

        <div class="AboutUsHolder R f-in bb">
            <img class="img_l" src="@/assets/caj_o_nas.png">
            <div>
                <h1>Naše cíle</h1>
                <p>
                    Cílem naší firmy je přinést českým zákazníkům <span>autentický zážitek</span> z pití čaje, který je hluboce zakořeněný v <span>gruzínské kultuře a tradici</span>.
                    Chceme zvýšit povědomí o gruzínských čajích, které jsou v České republice zatím málo známé, a nabídnout alternativu k běžným čajům dostupným na trhu.
                    Naším cílem je také <span>podporovat malé gruzínské farmáře</span> a přispívat k rozvoji jejich komunit tím, že jim poskytujeme <span>spravedlivou odměnu za jejich vysoce kvalitní produkty</span>.
                    Do budoucna plánujeme <span>rozšířit naši nabídku</span> o nové druhy čajů a spolupracovat s dalšími farmáři, abychom vám mohli přinést co nejširší spektrum chutí a vůní. 
                    Naším dlouhodobým cílem je stát se předním dovozcem gruzínských čajů v České republice a <span>vytvořit komunitu lidí</span>, kteří sdílejí naši lásku k čaji a zájem o udržitelnou podporu malých podniků.
                </p>
            </div>
        </div>

        <div class="AboutUsHolder R">
            <div>
                <h1>Co stojí za založením?</h1>
                <p>
                    Naše firma vznikla díky společné vášni k čaji, kterou jsme objevili během <span>studia na vysoké škole</span>. 
                    Během cesty do Gruzie jsme se zamilovali do <span>tamní čajové kultury</span> a rozhodli se, že tyto jedinečné čaje přivezeme i do České republiky. 
                    Bez velkých zkušeností, ale s <span>obrovským nadšením</span> jsme začali budovat firmu od základů.
                    Po večerech jsme vybírali ty nejlepší čaje, <span>navazovali vztahy s místními farmáři</span> a plánovali, jak přinést jejich produkty na český trh. 
                    Díky podpoře blízkých a naší vytrvalosti se nám <span>podařilo vytvořit firmu</span>, která nabízí nejen skvělé čaje, ale také podporuje malé farmáře v Gruzii.
                </p>
            </div>
            <img src="@/assets/caj_o_nas.png">
        </div>
    </div>
</template>

<style scoped>
    @font-face {
        font-family: 'SansitaOne';
        src: URL('@/assets/SansitaOne.ttf') format('truetype');
    }
    @font-face {
        font-family: 'ReadexPro';
        src: URL('@/assets/ReadexPro.ttf') format('truetype');
    }

    img{
        width: 420px;
        height: 420px;
        margin: 0;
        margin-right: 8%;
        margin-left: auto;
        margin-top: 4%;
    }

    #superHeader{
        font-size: 3rem;
    }

    .img_l{
        margin-left: 8%;
        margin-right: auto;
    }

    span{
        font-weight: 600;
    }
    .mainHolder{
        width: 100%;
    }
    .headerHolder{
        font-family: 'SansitaOne';
        padding-top: 4rem;
        padding-bottom: 4rem;
        text-align: center;
        border-radius: 0% 0% 100% 100% / 100% 100% 70% 70% ;
        color:white;
        background-color: rgb(157, 65, 0);
        font-size: 1.5rem;
    }
    .AboutUsHolder{
        margin-top: 6%;
        padding-bottom: 6%;
        margin-left: 8%;
        margin-right: 8%;
        line-height: 1.6rem;
        width: 84%;
        display: flex;
        flex-wrap: wrap;
    }

    .AboutUsHolder h1{
        font-family: 'SansitaOne';
        font-size: 2.2rem;
    }

    .AboutUsHolder p{
        padding-top:1rem;
        color:rgba(0, 0, 0, 0.7);
        font-size: 1.1rem;
        width: 550px;
        font-family: "ReadexPro";
    }
    .R{
        justify-content: flex-end;
    }
    @media screen and (max-width: 1254px) {
        .AboutUsHolder{
            justify-content: center;
            text-align: center;
        }
        .bb{
            border-bottom: 3px solid rgba(0, 0, 0, 0.3);
        }
        .AboutUsHolder p{
            padding-bottom: 50px;
            margin-right: auto;
            margin-left: auto;
        }
        img{
            margin: auto;
        }

        .img_l{
            margin:auto;
        }
        .f-in{
            flex-direction: column-reverse;
        }
    }
    @media screen and (max-width: 700px) {
        .AboutUsHolder p{
        padding-top:1rem;
        color:rgba(0, 0, 0, 0.7);
        font-size: 0.9rem;
        width: 95%;
    }
    .AboutUsHolder h1{
        font-family: 'SansitaOne';
        font-size: 1.6rem;
    }
    .R{
        justify-content: flex-start;
    }
    .f-in{
        flex-wrap: nowrap;
    }
    img{
        width:95%;
        height: auto;
    }
    h1{
        padding-right: 0;
    }
    .headerHolder{
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
        text-align: center;
        border-radius: 0;
        color:white;
        border-radius: 0% 0% 100% 100% / 100% 100% 20% 20% ;
    }
    #superHeader{
        font-size: 2.3rem;
    }
}
    
</style>