<template>
    <div>
        <div id="ItemViewHolder">
          <ItemSmallView v-for="item in products_vals" :itemName="item[0].name" :itemPrice="item[0].price" :itemDescription="item[0].description" :imgUrl="item[0].imgurl" :itemStock="item[0].stock" :itemNew="item[0].new" :itemId="item[0].itemId" :key="item.pk"/>
        </div>
    </div>
</template>

<script>
import ItemSmallView from './ItemSmallView.vue';
import axios from 'axios';

export default {
name: 'SmallViewer',
components: {
    ItemSmallView
},
data() {
    return {
        products_vals: {},
        n: 4,
      }
    },
beforeMount() 
    {
      this.getResponse(this.n);

    },
methods: {
    getImage(img){
        return require(`@/assets/${img}.png`)
    },
    async getResponse(){
             var response = (await axios.get('http://130.61.53.57/api/Sortiment/')).data;
             var result = [];

             for(var i in response)
             {
                result.push([response[i].fields]);
                var sorted = this.quickSortPopularity(result);
                this.products_vals = (sorted.reverse()).slice(0, this.n)
             }
    },
    quickSortPopularity(arr){
                if (arr.length <= 1) {
                    return arr;
                }

                let pivot = arr[0][0].popular;
                let leftArr = [];
                let rightArr = [];

                for (let i = 1; i < arr.length; i++) {
                    if (arr[i][0].popular < pivot) {
                        leftArr.push(arr[i]);
                    } else {
                        rightArr.push(arr[i]);
                    }
                }
                pivot = arr[0];

                return [...this.quickSortPopularity(leftArr), pivot, ...this.quickSortPopularity(rightArr)];
            }
}
}
</script>


<style scoped>

#ItemViewHolder{
    width: 1200px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
    @media screen and (max-width: 1200px) {
    #ItemViewHolder{
      width: 900px;
  
  }
  }
  @media screen and (max-width: 900px) {
    #ItemViewHolder{
      width: 600px;
  
  }
  }
  @media screen and (max-width: 600px) {
    #ItemViewHolder{
      width: 450px;
  }
  }
  @media screen and (max-width: 450px) {
    #ItemViewHolder{
      width: 300px;
  }
  }
</style>
