<template>
  <div>
    <NavBar />
    <RouterView />
    <FooterComponentVue />
  </div>
</template>

<script>
import FooterComponentVue from './components/FooterComponent.vue'
import NavBar from './components/NavBar.vue'

export default{
  name: "App",
  components:{
    NavBar,
    FooterComponentVue
  }
}

</script>

<style>
  body{
    margin: 0;
  }
</style>
