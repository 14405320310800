<template>
    <div id="mainHolder">
        <div class="headerContainer">
          <h1>Košík</h1> 
          <div>{{ (cookieResult.items).length }}</div>
        </div>
        <div class="productLine darkBg" v-if="(cookieResult.items).length > 0">
          <p class="important">Název</p>
          <p class="important">Množství</p>
          <p class="important">Cena</p>
        </div>
        <div v-if="(cookieResult.items).length > 0" class="productLine" v-for="product in cookieResult.items">
          <p>{{ product.productName }}</p>
          <p>{{ product.ammount + "g" }}</p>
          <p>{{ product.ammount * product.pricePerG + " Kč"}}</p>
        </div>
        <div class="productLine btmSpace darkBg" v-if="(cookieResult.items).length > 0">
          <p class="important">Celková cena: </p>
          <p></p>
          <p class="important">{{ getPrice() + " Kč" }}</p>
        </div>
        <div id="emptyCart" v-if="(cookieResult.items).length == 0">Zde je prázdno...</div>
        <h2 id="headerSales">Podívej se na naše <br><span>TOP produkty</span></h2>
        <SmallProductShower />
    </div>
</template>
  
<script>
import axios from 'axios'
import SmallProductShower from '@/components/SmallProductShower.vue';

axios.defaults.withCredentials = true;

export default {
  name: 'CartView',
  components: {
    SmallProductShower,
  },
  data(){
    return{
      cookieResult: this.getCookie("cart"),
      dat: document.cookie
    }
  },
  methods: {
    getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                cookieValue = cookieValue.replaceAll("\\","")
                cookieValue = cookieValue.replaceAll("054",",")
                break;
            }
        }
    }
    if(cookieValue == null){
      return JSON.parse("{ 'items' : []}".replaceAll("'","\""))
    }
    return JSON.parse((("{ 'items' :  ["+cookieValue.substring(1, cookieValue.length-1)+"]}").replaceAll("'","\"")).replaceAll("}{","},{"));
  },
  getPrice(){
    var total_sum = 0;
    for(var i = 0; i < (this.cookieResult.items).length;i++){
      total_sum += (this.cookieResult.items)[i].pricePerG*(this.cookieResult.items)[i].ammount;
    }
    return total_sum;
  },
  }
}

</script>

<style scoped>
@font-face {
    font-family: 'SansitaOne';
    src: URL('@/assets/SansitaOne.ttf') format('truetype');
}
body{
  margin: 0;
  padding: 0;
}

#headerSales{
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  font-family: "SansitaOne";
  margin-bottom: 3%;
}

#headerSales span{
  color: rgb(213, 0, 0);
}

.darkBg{
  background-color: rgb(224, 224, 224) !important;
}

h1{
  font-family: "SansitaOne";
  font-size: 2rem;
  width: auto;
  padding-right: 5px;
}
.btmSpace{
  margin-bottom: 6%;
  border-bottom: 2px solid rgba(128, 128, 128, 0.6);
}

.headerContainer{
  display: inline-flex;
  align-items: center;
  width: 250px;
}

.headerContainer div{
  background-color: rgba(255, 0, 0, 0.8);
  font-family:sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 6px !important;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  line-height: 16px;
  text-align: center;
}

#mainHolder{
  padding-left: 16px;
  padding-right: 16px;
  width: calc(100% - 32px);
}

.important{
  font-size: 1.4rem;
  font-weight: 900;
}

#emptyCart{
  font-family: "SansitaOne";
  font-size: 4rem;
  font-weight: 600;
  padding-top: 15%;
  padding-bottom: 18%;
  text-align: center;
}

.productLine{
  width: calc(100% - 58px);
  height: 60px;
  display: flex;
  align-items: center;
  border-top: 2px solid rgba(128, 128, 128, 0.6);
  padding-left: 24px;
  padding-right: 24px;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: whitesmoke;
  justify-content: space-between;
}

@media screen and (max-width: 700px) {
    #emptyCart{
      font-size: 2.5rem;
      margin-bottom: calc(15% + 50px);
      margin-top: calc(15% + 50px);
  }
  #headerSales{
    font-size: 1.8rem;
  }
}
</style>