<template>
    <div id="comp-holder">
      <div id="popUp">
        <div>
          <h3>Vše proběhlo v pořádku</h3>
          <div id="selectHolder"> 
            <button v-on:click="hideAlert()" class="popUpBtn">Ok</button>
            <RouterLink v-on:click="hideAlert()" to="/Cart" class="popUpBtn">Do košíku</RouterLink>
          </div>
          
        </div>
      </div>
      <div class="upperHolder">
        <img :src="getImage('zeleny_caj')">
        <div class="summerize">
          <h1>{{ itemObj.name }}</h1>
          <p id="description">{{ itemObj.longDescription }}</p>
        </div>
        <p id="priceTag">{{ itemObj.price }} Kč / g</p>
        <select v-model="selectedAmm" name="g-sel" id="selector">
           <option value="50">50g</option>
           <option value="100">100g</option>
           <option value="150">150g</option>
           <option value="200">200g</option>
           <option value="250">250g</option>
           <option value="300">300g</option>
           <option value="350">350g</option>
           <option value="400">400g</option>
           <option value="450">450g</option>
           <option value="500">500g</option>
        </select>
        <p class="inline-ic-txt">
          Doprava zdarma od nákupu nad 1000 Kč <br>
          <br>
          Bonusový dárek k nákupům nad 2500 Kč
        </p>
        <p id="priceCalc">Celková cena: <span style="font-weight: 400;">{{ selectedAmm*itemObj.price }} Kč</span></p>
        <button id="AddItemBtn" v-on:click="postAddCart()">
          Přidat do košíku
        </button>
      </div>
    </div>
</template>
  
<script>
import axios from 'axios'

axios.defaults.withCredentials = true;

export default {
  name: 'ItemSite',
  data(){
    return{
      productId: this.$route.params.productId,
      selectedAmm: this.$route.params.amm,
      itemObj: this.getResponse(),
    }
  },
  methods: {
    getImage(img){
        return require(`@/assets/${img}.png`)
    },
    async getResponse(){
             var response = (await axios.get('http://130.61.53.57/api/Sortiment/')).data;
             var result = [];

             for(var i in response)
             {
                result.push([response[i].fields]);
             }

             result.forEach((x, i) => {
              if(x[0].itemId == parseInt(this.productId)){
                console.log(x[0])
                this.itemObj = x[0]
              }
              });
        },
      async postAddCart(){
      
      await axios.post('http://130.61.53.57/api/addToCart/',{
        id: this.itemId,
        ammount: this.selectedAmm,
        productName: this.itemObj.name,
        pricePerG: this.itemObj.price   
        
      }, {
        withCredentials:true,
        headers: {
          'X-CSRFToken': this.getCookie('csrftoken')
        }}
    ).then(resp => {
      if(resp.data == "Ok"){
        document.getElementById("popUp").style.display = "block";
        document.body.style.overflow = "hidden";
        window.scrollTo(0, 0);
      }
    }).catch(e =>{
      console.log(e)
    })
    
    },
    getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
},
hideAlert(){
  document.getElementById("popUp").style.display = 'none';
  document.body.style.overflow = "visible";
}
}}

</script>

<style scoped>

#selectHolder{
  margin-top: 20px !important;
  width: 400px !important;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
}

#popUp{
  position: absolute;
  width: 100%;
  height:200%;
  background-color: rgba(0, 0, 0, 0.4);
  top:0;
  display: none;
}

#popUp div{
  margin-top: 50px;
  width: 500px;
  padding: 10px;
  position: sticky;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: white;
  border-radius: 20px;
}

#popUp div h3{
  font-size: 2.5rem;
  font-weight: 600;
  margin:10px;
}

.popUpBtn{
  width: 40%;
  height: 40px;
  background-color: olivedrab;
  border: none;
  text-decoration: none;
  justify-content: center;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.inline-ic-txt{
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: 600;
}

#priceCalc{
  color:black;
  font-size: 1.3rem;
  font-weight: 600;
}

#AddItemBtn{
  width: 180px;
  height: 48px;
  border: solid rgb(0, 0, 0) 3px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  font-size: 1.1rem;
  font-weight: 600;
  transition: 0.2s;
  margin-bottom: 40px;
}

#AddItemBtn:hover{
  background-color: rgba(0, 0, 0, 1);
  color:whitesmoke;
}
#selector{
  padding: 5px;
  font-size: 1.3rem;
  border:none;
  background-color: rgb(231, 231, 231);
  border-radius: 8px;
}

.upperHolder{
  width: 100%;
}

#priceTag{
  font-size: 1.4rem;
  font-weight: 600;
}

.upperHolder img{
  float:left;
  margin: 5%;
  width: 550px;
  height: auto;
  margin-top: 0;
}

#description{
  font-size: 1.2rem;
  margin-right: 7%;
}

#sideTextHolder{
  margin-top: 35px;
}

@media screen and (max-width: 1100px) {
    .upperHolder img{
      width: 80%;
      padding-left: 10%;
      padding-right: 10%;
      margin: 0;
      margin-bottom: 5%;
    }

    .upperHolder button, .upperHolder select, .upperHolder p, .upperHolder h1{
      margin-left: 5%;
    }
}

</style>
