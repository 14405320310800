<template>
    <div>
        <div id="grid-wrapper">
            <div class="item1">

            </div>
            <div class="item2">

            </div>
            <div class="item3">

            </div>
        </div>
    </div>
</template>

<script>

export default {
name: 'ActionGrid',
data() {
    return {

      }
    },
methods: {
 bum(){
    return 
 },
}
}
</script>


<style scoped>

#grid-wrapper{
  width: 1200px;
  height: 600px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}

.item1{
    grid-column: 1 / 3;
    grid-row: 1 / 4;
    background-image: url("@/assets/halloween_poster_homepg.jpeg");
    background-size: cover;
    border-radius: 8px;
}

.item2{
    grid-column: 3 / 4;
    grid-row: 1 / 4;
    background-image: url("@/assets/vanoce_poster_vertical.png");
    background-size: cover;
    border-radius: 8px;
}
.item3{
    grid-column: 1 / 4;
    grid-row: 4;
    background-image: url("@/assets/mikulas_poster_horizontal.png");
    background-size: cover;
    border-radius: 8px;
}

@media screen and (max-width: 1250px) {
    #grid-wrapper{
        width: 1000px;
        height: 500px;
    }
}

@media screen and (max-width: 1000px) {
    #grid-wrapper{
        display: none;
    }
}
</style>