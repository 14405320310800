<template>
    <div class="footerHolder">
        <div class="footerBlock">
            <h2 style="margin-bottom: 2rem; font-family: 'FugazOne';">Koukni se:</h2>
            <p style="display: flex; align-items: center;">
                Aktulaity
            </p>

            <p style="display: flex; align-items: center;">
                O nás
            </p>

            <p style="display: flex; align-items: center;">
                Sortiment
            </p>
        </div>
        <div class="footerBlock">
            <h2 style="margin-bottom: 2rem; font-family: 'FugazOne';">Sleduj nás:</h2>
            <p style="display: flex; align-items: center;">
                <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 0.6rem;" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                    <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                    <path d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z" stroke="currentColor" stroke-width="1.5" />
                    <path d="M17.5078 6.5L17.4988 6.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                caje-g
            </p>

            <p style="display: flex; align-items: center;">
                <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 0.6rem;" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.18182 10.3333C5.20406 10.3333 5 10.5252 5 11.4444V13.1111C5 14.0304 5.20406 14.2222 6.18182 14.2222H8.54545V20.8889C8.54545 21.8081 8.74951 22 9.72727 22H12.0909C13.0687 22 13.2727 21.8081 13.2727 20.8889V14.2222H15.9267C16.6683 14.2222 16.8594 14.0867 17.0631 13.4164L17.5696 11.7497C17.9185 10.6014 17.7035 10.3333 16.4332 10.3333H13.2727V7.55556C13.2727 6.94191 13.8018 6.44444 14.4545 6.44444H17.8182C18.7959 6.44444 19 6.25259 19 5.33333V3.11111C19 2.19185 18.7959 2 17.8182 2H14.4545C11.191 2 8.54545 4.48731 8.54545 7.55556V10.3333H6.18182Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                </svg>
                Gruzinské čaje
            </p>

            <p style="display: flex; align-items: center;">
                <svg xmlns="http://www.w3.org/2000/svg" style="margin-right: 0.6rem;" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                    <path d="M3 21L10.5484 13.4516M21 3L13.4516 10.5484M13.4516 10.5484L8 3H3L10.5484 13.4516M13.4516 10.5484L21 21H16L10.5484 13.4516" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                g-caje
            </p>
        </div>
        <div class="footerBlock">
            <h2 style="margin-bottom: 2rem; font-family: 'FugazOne';">Zakladtelé:</h2>
            <p style="display: flex; align-items: center;">
                Gabriel Asatiani
            </p>
            <p style="display: flex; align-items: center;">
                Tomáš Diviš
            </p>
        </div>
        <div class="footerBlock">
            <h2 style="margin-bottom: 2rem; font-family: 'FugazOne';">Kontakty:</h2>
            <p style="display: flex; align-items: center;">
                <svg class="svg-icon" style="margin-right: 0.6rem;" viewBox="0 0 20 20" width="28" height="28">
							<path d="M10,1.375c-3.17,0-5.75,2.548-5.75,5.682c0,6.685,5.259,11.276,5.483,11.469c0.152,0.132,0.382,0.132,0.534,0c0.224-0.193,5.481-4.784,5.483-11.469C15.75,3.923,13.171,1.375,10,1.375 M10,17.653c-1.064-1.024-4.929-5.127-4.929-10.596c0-2.68,2.212-4.861,4.929-4.861s4.929,2.181,4.929,4.861C14.927,12.518,11.063,16.627,10,17.653 M10,3.839c-1.815,0-3.286,1.47-3.286,3.286s1.47,3.286,3.286,3.286s3.286-1.47,3.286-3.286S11.815,3.839,10,3.839 M10,9.589c-1.359,0-2.464-1.105-2.464-2.464S8.641,4.661,10,4.661s2.464,1.105,2.464,2.464S11.359,9.589,10,9.589"></path>
				</svg>
                Česká republika, Praha
            </p>

            <p style="display: flex; align-items: center;">
                <svg class="svg-icon" style="margin-right: 0.6rem;" viewBox="0 0 20 20" width="28" height="28">
							<path d="M17.388,4.751H2.613c-0.213,0-0.389,0.175-0.389,0.389v9.72c0,0.216,0.175,0.389,0.389,0.389h14.775c0.214,0,0.389-0.173,0.389-0.389v-9.72C17.776,4.926,17.602,4.751,17.388,4.751 M16.448,5.53L10,11.984L3.552,5.53H16.448zM3.002,6.081l3.921,3.925l-3.921,3.925V6.081z M3.56,14.471l3.914-3.916l2.253,2.253c0.153,0.153,0.395,0.153,0.548,0l2.253-2.253l3.913,3.916H3.56z M16.999,13.931l-3.921-3.925l3.921-3.925V13.931z"></path>
				</svg>
                g-caje@gmail.com
            </p>

            <p style="display: flex; align-items: center;">
                <svg class="svg-icon" style="margin-right: 0.6rem;" viewBox="0 0 20 20" width="28" height="28">
							<path d="M13.372,1.781H6.628c-0.696,0-1.265,0.569-1.265,1.265v13.91c0,0.695,0.569,1.265,1.265,1.265h6.744c0.695,0,1.265-0.569,1.265-1.265V3.045C14.637,2.35,14.067,1.781,13.372,1.781 M13.794,16.955c0,0.228-0.194,0.421-0.422,0.421H6.628c-0.228,0-0.421-0.193-0.421-0.421v-0.843h7.587V16.955z M13.794,15.269H6.207V4.731h7.587V15.269z M13.794,3.888H6.207V3.045c0-0.228,0.194-0.421,0.421-0.421h6.744c0.228,0,0.422,0.194,0.422,0.421V3.888z"></path>
					</svg>
                    +420 XXX XXX XXX
            </p>
        </div>
        <div class="footerSuperBlock">
            <img src="@/assets/gtea_wide_logo.png">
        </div>
    </div>
</template>

<script>

export default {
name: 'FooterComponent',
}
</script>


<style scoped>
    @font-face {
        font-family: 'FugazOne';
        src: URL('@/assets/FugazOne.ttf') format('truetype');
    }
    @font-face {
    font-family: 'ReadexPro';
    src: URL('@/assets/ReadexPro.ttf') format('truetype');
  }

    .footerHolder{
        width:calc(100% - 20px);
        background-color: whitesmoke;
        display: flex;
        flex-wrap: wrap;
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 20px;
        border-top: 2px solid rgba(0, 0, 0, 0.4);
        font-family: "ReadexPro";
    }
    .footerBlock{
        width: 22%;
        padding-top: 2rem;
        padding-left: 3%;
    }

    .footerSuperBlock{
        width: 100%;
        text-align: center;
    }
    .footerSuperBlock img{
        width: 30%;
    }

    @media screen and (max-width: 900px) {
    .footerBlock{
        width: 47%;
        text-align: center;
        justify-content: center;
        }
        .footerBlock p{
        justify-content: center;
        }
    }
    @media screen and (max-width: 450px) {
    .footerBlock{
        width:97%;
        text-align: center;
        justify-content: center;
        margin-bottom: 3rem;
        }
        .footerBlock p{
        justify-content: center;
        }
    }

</style>