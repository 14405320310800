<template>
    <div class="SortHolder">
        <div class="FilterVisibility">
            <div id="selectionBox">
                <h1>Filtr</h1>
                <svg @click="openFilter()" id="closeBtn" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <g fill="none" fill-rule="evenodd" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
                        <path d="M13 1 1 13M1 1l12 12"></path>
                    </g>
                </svg>
                <div id="form">
            <div class="selClassHolder">
                <h3>Druhy:</h3>
                <div>
                    <input v-model="formData.cerny_caj" id="cerne_caje" type="checkbox">
                    <label for="cerne_caje">Černé čaje</label>
                </div>
                <div>
                    <input v-model="formData.zeleny_caj" id="zelene_caje" type="checkbox">
                    <label for="zelene_caje">Zelené čaje</label>
                </div>
                <div>
                    <input v-model="formData.bily_caj" id="bile_caje" type="checkbox">
                    <label for="bile_caje">Bílé čaje</label>
                </div>
                <div>
                    <input v-model="formData.oolong" id="oolong" type="checkbox">
                    <label for="oolong">Oolong</label>
                </div>
                <div>
                    <input v-model="formData.puerh" id="puerh" type="checkbox">
                    <label for="puerh">Pu-erh</label>
                </div>
            </div>
             
            <div class="selClassHolder">
                <h3>Funkční čaje:</h3>
                <div>
                    <input v-model="formData.c_pro_lepsi_spanek" id="spanek" type="checkbox">
                    <label for="spanek">Čaje pro lepší spánek</label>
                </div>
                <div>
                    <input v-model="formData.c_pro_posileni_imunity" id="imunita" type="checkbox">
                    <label for="imunita">Čaje pro posílení imunity</label>
                </div>
                <div>
                    <input v-model="formData.c_detox" id="detox" type="checkbox">
                    <label for="detox">Čaje detoxikační</label>
                </div>
                <div>
                    <input v-model="formData.hermankove_caje" id="hermankove" type="checkbox">
                    <label for="hermankove">Heřmánkové čaje</label>
                </div>
                <div>
                    <input v-model="formData.matove_caje" id="matove" type="checkbox">
                    <label for="matove">Mátové čaje</label>
                </div>
                <div>
                    <input v-model="formData.rooibos" id="rooibos" type="checkbox">
                    <label for="rooibos">Rooibos</label>
                </div>
                <div>
                    <input v-model="formData.zazvorovy_caj" id="zazvor" type="checkbox">
                    <label for="zazvor">Zázvorový čaj</label>
                </div>
            </div>

            <div class="selClassHolder">
                <h3>Speciální nabídky:</h3>
                <div>
                    <input v-model="formData.darkova_baleni" id="dbal" type="checkbox">
                    <label for="dbal">Dárková balení</label>
                </div>
                <div>
                    <input v-model="formData.specialni_mixy" id="smix" type="checkbox">
                    <label for="smix">Speciální mixy</label>
                </div>
                <div>
                    <input v-model="formData.ostatni" id="ostatni" type="checkbox">
                    <label for="ostatni">Ostatní</label>
                </div>
            </div>

            <div class="selClassHolder">
                <h4 style="margin-bottom:10px;">Cena (Kč/g)</h4>
                <div>
                    <div style="width: 200px; margin-left: -5px;">
                        <input type="number" value="1" step="1" min="1" max="100" class="halved" id="priceRangeMin"> -
                        <input type="number" value="100" step="1" min="1" max="100" class="halved" id="procerangeMax">
                    </div>
                </div>
                <h4>Upřednostnit</h4>
                <div>
                    <input v-model="formData.dostupne" id="dosz" type="checkbox">
                    <label for="dosz">Dostupné zboží</label>
                </div>
                <div>
                    <input v-model="formData.zlevneno" id="zlevnene" type="checkbox">
                    <label for="zlevnene">Zlevněné zboží</label>
                </div>
                <div>
                    <input v-model="formData.nove" id="novinky" type="checkbox">
                    <label for="novinky">Novinky</label>
                </div>
            </div>
            <button @click="filterExtended(), openFilter()" id="submitButton">Použít</button>
        </div>
            </div>
        </div>
        <div class="FilterHolder">
            <div id="holder1">
                <button @click="clicked('1')" id="but1" class="left-rad"> Nejprodávanější</button>
                <button @click="clicked('2')" id="but2" class="left-bor right-bor"> Nejdražší</button>
                <button @click="clicked('3')" id="but3" class="right-rad"> Nejlevnější</button>
            </div>
            <div id="holder2">
                <button @click="openFilter()">rozšířený filtr</button>
                <button id="res_fil" @click="resetFilter()">resetovat roz. filtr</button>
            </div>
            <div id="holder3">
                <div @click="() => {this.formData.cerny_caj = !this.formData.cerny_caj; filterExtended(); return}" v-if="this.formData.cerny_caj" class="filterBubble">Černé čaje</div>
                <div @click="() => {this.formData.bily_caj = !this.formData.bily_caj; filterExtended(); return}" v-if="this.formData.bily_caj" class="filterBubble">Bílé čaje</div>
                <div @click="() => {this.formData.zeleny_caj = !this.formData.zeleny_caj; filterExtended(); return}" v-if="this.formData.zeleny_caj" class="filterBubble">Zelené čaje</div>
                <div @click="() => {this.formData.oolong = !this.formData.oolong; filterExtended(); return}" v-if="this.formData.oolong" class="filterBubble">Oolong</div>
                <div @click="() => {this.formData.puerh = !this.formData.puerh; filterExtended(); return}" v-if="this.formData.puerh" class="filterBubble">Pu-erh</div>
                <div @click="() => {this.formData.c_pro_lepsi_spanek = !this.formData.c_pro_lepsi_spanek; filterExtended(); return}" v-if="this.formData.c_pro_lepsi_spanek" class="filterBubble">Čaje pro lepší spánek</div>
                <div @click="() => {this.formData.c_pro_posileni_imunity = !this.formData.c_pro_posileni_imunity; filterExtended(); return}" v-if="this.formData.c_pro_posileni_imunity" class="filterBubble">Čaje pro posílení imunity</div>
                <div @click="() => {this.formData.c_detox = !this.formData.c_detox; filterExtended(); return}" v-if="this.formData.c_detox" class="filterBubble">Detoxikační čaje</div>
                <div @click="() => {this.formData.hermankove_caje = !this.formData.hermankove_caje; filterExtended(); return}" v-if="this.formData.hermankove_caje" class="filterBubble">Heřmánkové čaje</div>
                <div @click="() => {this.formData.zazvorovy_caj = !this.formData.zazvorovy_caj; filterExtended(); return}" v-if="this.formData.zazvorovy_caj" class="filterBubble">Zázvorové čaje</div>
                <div @click="() => {this.formData.rooibos = !this.formData.rooibos; filterExtended(); return}" v-if="this.formData.rooibos" class="filterBubble">Rooibos</div>
                <div @click="() => {this.formData.matove_caje = !this.formData.matove_caje; filterExtended(); return}" v-if="this.formData.matove_caje" class="filterBubble">Mátové čaje</div>
                <div @click="() => {this.formData.darkova_baleni = !this.formData.darkova_baleni; filterExtended(); return}" v-if="this.formData.darkova_baleni" class="filterBubble">Dárková bálení</div>
                <div @click="() => {this.formData.specialni_mixy = !this.formData.specialni_mixy; filterExtended(); return}" v-if="this.formData.specialni_mixy" class="filterBubble">Speciální mixy</div>
                <div @click="() => {this.formData.ostatni = !this.formData.ostatni; filterExtended(); return}" v-if="this.formData.ostatni" class="filterBubble">Ostatní</div>
                <div @click="() => {this.formData.zlevneno = !this.formData.zlevneno; filterExtended(); return}" v-if="this.formData.zlevneno" class="filterBubble">Zlevněné</div>
                <div @click="() => {this.formData.nove = !this.formData.nove; filterExtended(); return}" v-if="this.formData.nove" class="filterBubble">Nové</div>
                <div @click="() => {this.formData.dostupne = !this.formData.dostupne; filterExtended(); return}" v-if="this.formData.dostupne" class="filterBubble">Dostupné</div>
            </div>
            <div id="resultDisplay">
                Nalezeno {{ resultAmmount }} produktů
            </div>
        </div>
        <div class="productsHolder">
                 <div id="emptyFilter" v-if="this.products_vals == 0">Pro tento filtr bohužel neexistují žádné produkty, zkuste upravit vaše filtry</div>
                   <ItemSmallView v-for="item in products_vals" :itemName="item[0].name" :itemPrice="item[0].price" :itemDescription="item[0].description" :imgUrl="item[0].imgurl" :itemStock="item[0].stock" :itemNew="item[0].new" :itemId="item[0].itemId" :key="item.pk"/>
            </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import ItemSmallView from '@/components/ItemSmallView.vue';

    export default{
        name:"SortimentSite",
        components: {
            ItemSmallView,
        },
        data(){
            return {
                products_vals: {},
                filterOpen: false,
                resultAmmount: 0,
                formData: {
                    cerny_caj: false,
                    zeleny_caj: false,
                    bily_caj: false,
                    oolong: false,
                    puerh: false,
                    c_pro_lepsi_spanek: false,
                    c_pro_posileni_imunity: false,
                    c_detox: false,
                    hermankove_caje: false,
                    zazvorovy_caj: false,
                    rooibos: false,
                    matove_caje: false,
                    darkova_baleni: false,
                    specialni_mixy: false,
                    ostatni: false,
                    dostupne: false,
                    zlevneno: false,
                    nove: false,
                }



            }
        },
        beforeMount() {
            this.getResponse().then(response => {
                var result = [];

                for(var i in response)
                    result.push([response[i].fields]);
                console.log(result)
                this.products_vals = result;
                this.resultAmmount = Object.keys(this.products_vals).length;
            }).catch(e => {
                console.log(e);
            });;
        },
        methods: {
            async getResponse(){
             var resp = (await axios.get('http://130.61.53.57/api/Sortiment/'));
             console.log(resp);
             return(resp.data);
            },
            clicked(but){
                if(document.getElementById("but"+but).style.backgroundColor == "olivedrab"){
                    this.resetbgs();
                    var result = [];
                    for(var i in this.products_vals) {
                        result.push([this.products_vals[i][0]]); 
                    }
                    this.products_vals = this.quickSortId(result);
                    return;
                }else{
                    this.resetbgs();
                    this.filter(parseInt(but));
                    document.getElementById("but"+but).style.backgroundColor = "olivedrab";
                }
            },
            resetbgs(){
                document.getElementById("but1").style.backgroundColor = "rgba(128, 128, 0, 0.4)";
                document.getElementById("but2").style.backgroundColor = "rgba(128, 128, 0, 0.4)";
                document.getElementById("but3").style.backgroundColor = "rgba(128, 128, 0, 0.4)";
            },
            resetFilter(){
                for(var f in this.formData){
                        this.formData[f] = false;
                }
                this.resetbgs();
                this.filterExtended();
            },
            filterExtended(){
                this.getResponse().then(response => {
                    var result = [];
                    var applied_filters = [];
                    var product_vals = "";
                    for(var f in this.formData){
                        if(this.formData[f]){
                            applied_filters.push(f)
                        }
                    }
                    console.log(applied_filters)
                    if(applied_filters.length > 0){
                        for(var i in response){
                            product_vals = response[i].fields
                            for(var filter in applied_filters){
                                if(product_vals[applied_filters[filter]]){
                                    result.push([product_vals]);
                                    break;
                                }
                            }
                            
                    }
                    }else{
                        for(var i in response){
                            result.push([response[i].fields]);
                        }
                    }
                    this.resetbgs();
                    this.products_vals = result;
                    this.resultAmmount = Object.keys(this.products_vals).length;
                })
            },

            filter(type){
                var result = [];
                for(var i in this.products_vals) {
                    result.push([this.products_vals[i][0]]); 
                }
                    if(type == 3){
                        var sorted = this.quickSort(result);
                        this.products_vals = sorted;
                    }else if(type == 2){
                        var sorted = this.quickSort(result);
                        this.products_vals = sorted.reverse();
                    }else if(type == 1){
                        var sorted = this.quickSortPopularity(result);
                        this.products_vals = sorted.reverse();
                    }else if(type == 0){
                        var sorted = this.quickSortId(result);
                        this.products_vals = sorted;
                        console.log(sorted)
                    }

                    this.resultAmmount = Object.keys(this.products_vals).length;
            },
            openFilter(){
                if(this.filterOpen){
                    document.querySelector(".FilterVisibility").style.display = "none";
                    this.filterOpen = !this.filterOpen
                }else{
                    document.querySelector('.FilterVisibility').style.display = "flex";
                    this.filterOpen = !this.filterOpen
                }
            },
            quickSort(arr){
                if (arr.length <= 1) {
                    return arr;
                }

                let pivot = arr[0][0].price;
                let leftArr = [];
                let rightArr = [];

                for (let i = 1; i < arr.length; i++) {
                    if (arr[i][0].price < pivot) {
                        leftArr.push(arr[i]);
                    } else {
                        rightArr.push(arr[i]);
                    }
                }
                pivot = arr[0];

                return [...this.quickSort(leftArr), pivot, ...this.quickSort(rightArr)];
            },
            quickSortId(arr){
                if (arr.length <= 1) {
                    return arr;
                }

                let pivot = arr[0][0].itemId;
                let leftArr = [];
                let rightArr = [];

                for (let i = 1; i < arr.length; i++) {
                    if (arr[i][0].itemId < pivot) {
                        leftArr.push(arr[i]);
                    } else {
                        rightArr.push(arr[i]);
                    }
                }
                pivot = arr[0];

                return [...this.quickSortId(leftArr), pivot, ...this.quickSortId(rightArr)];
            },
            quickSortPopularity(arr){
                if (arr.length <= 1) {
                    return arr;
                }

                let pivot = arr[0][0].popular;
                let leftArr = [];
                let rightArr = [];


                for (let i = 1; i < arr.length; i++) {
                    if (arr[i][0].popular < pivot) {
                        leftArr.push(arr[i]);
                    } else {
                        rightArr.push(arr[i]);
                    }
                }
                pivot = arr[0];



                return [...this.quickSortPopularity(leftArr), pivot, ...this.quickSortPopularity(rightArr)];
            },
        },
    }
</script>


<style scoped>
    @font-face {
        font-family: 'ReadexPro';
        src: URL('@/assets/ReadexPro.ttf') format('truetype');
    }

    #resultDisplay{
        font-family: "ReadexPro";
        margin-left: 10%;
        color: rgba(0, 0, 0, 0.65);
        padding-left: 15px;
        margin-top: 20px;
        font-size: 0.9rem;

    }

    #emptyFilter{
        text-align: center;
        width: 100%;
        padding:150px;
        font-weight: 700;
        font-size: 2rem;
    }

    #res_fil{
        color: rgba(253, 0, 0, 0.9) !important;
    }

    #res_fil:hover{
        color: rgba(153, 0, 0, 0.9) !important;
    }

    .filterBubble{
        padding: 14px;
        margin-right: 10px;
        background-color: rgba(106, 142, 35, 0.9);
        border-radius: 40px;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 5px;
    }
    .filterBubble:hover{
        background-color: rgba(106, 142, 35, 0.7);

    }

    .filterBubble::after{
         content: "\2715";
         margin-left: 8px; 
    }

    #submitButton{
        width: 160px;
        height: 40px;
        position: absolute;
        bottom: calc(((100% - 600px) / 2) + 30px);
        right: calc(((100vw - 850px) / 2) + 30px);
        border: 3px solid black;
        border-radius: 4px;
        color: black;
        font-weight: bold;
        background-color: white;
        cursor: pointer;
    }

    #submitButton:hover {
        background-color: rgb(179, 179, 179);
    }

    .productsHolder{
        width: 1200px;
        margin:auto;
        margin-top: 30px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap:wrap;
    }

    #closeBtn{
        position: absolute;
        top: calc(((100% - 600px) / 2) + 40px);
        right: calc(((100vw - 850px) / 2) + 30px);
        cursor: pointer;
    }

    #selectionBox{
        width: 850px;
        height: 600px;
        background-color: white;
        box-shadow: 0px 0px 15px rgb(45, 45, 45);
        border-radius: 8px;
        padding-left: 40px;
        font-family: "ReadexPro";
        font-weight: 300;
    }

    #form {
        display: flex;
        flex-direction: row;
        width: auto;
        flex-wrap: wrap;
    }

    .selClassHolder{
        display: flex;
        flex-direction: column;
        padding-right: 20px !important;
    }

    #form div{
        padding:7px;
    }
    #form div label{
        padding-left:5px;
    }

    .SortHolder{
        width: 100%;
    }

    .halved{
        width: 40%;
    }

    .FilterHolder{
        padding:25px;
    }

    .FilterVisibility{
        display: none;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top:0;
        width: 100%;
        height: 100%;
        z-index: 10;
        justify-content: center;
        align-items: center;
        
    }

    .left-bor{
        border-left: solid 1px rgba(0, 0, 0, 0.3) !important;
    }
    .right-bor{
        border-right: solid 1px rgba(0, 0, 0, 0.3) !important;
    }

    #holder1{
        display: flex;
        flex-flow: row;
        width: 450px;
        justify-content: center;
        height: 60px;
        border-radius: 50%;
        margin-left: 10%;
    }
    #holder2{
        display: flex;
        flex-flow: row;
        border-radius: 50%;
        margin-left: 10%;
    }

    #holder2 button{
        background: none;
        border: none;
        font-size: 1rem;
        margin-left: 10px;
        margin-top: 20px;
        text-decoration: underline;
        color: rgb(0, 0, 0);
        cursor:pointer;
        transition: 0.1s;
    }

    #holder2 button:hover{
        color: rgb(46, 0, 154);
    }

    #holder1 button{
        margin-bottom: 10px;
        background: transparent;
        border: none;
        width: 150px;
        height: 60px;
        cursor: pointer;
        transition: 0.2s;
        background-color: rgba(128, 128, 0, 0.4);
        font-weight: 600;
    }
    .right-rad{
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        border-left: solid 1px rgba(0, 0, 0, 0.3) !important;
    }
    .left-rad{
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
        border-right: solid 1px rgba(0, 0, 0, 0.3) !important;
    }
    #holder1 button:hover{
        background-color: rgba(106, 142, 35, 0.75) !important;

    }

    #holder3{
        display: flex;
        flex-flow: row;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 10%;
        font-family: 'ReadexPro';
        flex-wrap: wrap;
    }
    @media screen and (max-width: 1200px) {
    .productsHolder{
      width: 900px;
  
  }
  }
  @media screen and (max-width: 900px) {
    .productsHolder{
      width: 600px;
    }
    #selectionBox{
        width: 700px;
        height: 700px;
    }
    #form div{
        padding:2px;
    } 

    #closeBtn{
        position: absolute;
        top: calc(((100% - 700px) / 2) + 40px);
        right: calc(((100vw - 700px) / 2) + 30px);
        cursor: pointer;
    }

    #submitButton{
        bottom: calc(((100% - 700px) / 2) + 30px);
        right: calc(((100vw - 700px) / 2) + 30px);
    }

    .selClassHolder{
        width: 200px;
    }
  
  }
  @media screen and (max-width: 700px) {
    #selectionBox{
        width: 500px;
    }

    #closeBtn{
        position: absolute;
        top: calc(((100% - 700px) / 2) + 40px);
        right: calc(((100vw - 500px) / 2) + 30px);
        cursor: pointer;
    }

    #submitButton{
        bottom: calc(((100% - 700px) / 2) + 30px);
        right: calc(((100vw - 500px) / 2) + 30px);
    }
  }
  @media screen and (max-width: 600px) {
    .productsHolder{
      width: 450px;
  }
  .FilterHolder{
    padding: 10px;
  }

  #holder1{
        width: 300px;
        height: 50px;
        margin-left: auto;
        margin-right: auto;
    }
    #holder2{
        width: 300px;
        height: 50px;
        margin-left: auto;
        margin-right: auto;
    }
   #holder1 button{
    margin-top: 10px;
        margin-bottom: 5px;
        width: 100px;
        height: 40px;
        font-weight: 600;
        font-size: 0.6rem;
    }
  }
  @media screen and (max-width: 500px) {
    .FilterVisibility{
        position: absolute;
    }
    #selectionBox{
        position: absolute;
        top:0;
        left:0;
        width: calc(100% - 40px);
        height: 1000px;
        overflow:auto;
    }
    #form div{
        padding:1px;
    } 

    #closeBtn{
        position: absolute;
        top: calc(((100% - 1000px) / 2) + 40px);
        right: calc(((100vw - 100vw) / 2) + 30px);
        cursor: pointer;
    }

    #submitButton{
        bottom: calc(((100% - 1000px) / 2) + 30px);
        right: calc(((100vw - 100vw) / 2) + 30px);
    }

    .selClassHolder{
        width: 100vw;
    }
  }

  @media screen and (max-width: 450px) {
    .productsHolder{
      width: 300px;
  }
  }
</style>
