<template>

        <nav>
            <RouterLink class="logo" to="/"><img class="logo" src="@/assets/gtea_wide_logo.png"></RouterLink>
            <div class="nav-a-holder">
                <RouterLink to="/Onas" class="lato-bold under-ani">O nás</RouterLink>
                <RouterLink to="/Dotazy" class="lato-bold under-ani">Dotazy</RouterLink>
                <RouterLink to="/Sortiment" class="lato-bold sortiment-a under-ani" >Sortiment</RouterLink>
                <div class="hide-sortiment nav-show-menu">
                        <RouterLink to="/" class="display-product-holder lato-regular" >Bylinné čaje</RouterLink>
                        <RouterLink to="/" class="display-product-holder lato-regular" >Porcované čaje</RouterLink>
                        <RouterLink to="/" class="display-product-holder lato-regular" >Speciální čaje</RouterLink>
                </div> 
                <RouterLink to="/" class="lato-bold sortiment-a under-ani">Aktulaity</RouterLink> 
                <RouterLink id="shopping-icon" to="/Cart"><svg class="w-6 h-6 text-gray-800 dark:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 10V6a3 3 0 0 1 3-3v0a3 3 0 0 1 3 3v4m3-2 .917 11.923A1 1 0 0 1 17.92 21H6.08a1 1 0 0 1-.997-1.077L6 8h12Z"/>
                  </svg>
                </RouterLink>      
            </div>
            <div class="mobileNav">
                <img id="mobImg" v-on:click="changeNav()" width="40" height="40" src="@/assets/menu-svgrepo-com.svg">
            </div>
            <div id="mobileNavBar" class="mobileNavLinks">
                <RouterLink v-on:click="changeNav()" to="/Onas" class="mobileEdit lato-bold ">O nás</RouterLink>
                <RouterLink v-on:click="changeNav()" to="/Dotazy" class="mobileEdit lato-bold ">Dotazy</RouterLink>
                <RouterLink v-on:click="changeNav()" to="/Sortiment" class="mobileEdit lato-bold sortiment-a" >Sortiment</RouterLink>
                <RouterLink v-on:click="changeNav()" to="/" class="mobileEdit lato-bold sortiment-a">Aktulaity</RouterLink> 
                <RouterLink id="s-icon-mbile" v-on:click="changeNav()" class="mobileEdit lato-bold sortiment-a" to="/Cart">
                    Košík
                  <svg class="w-6 h-6 text-gray-800 dark:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.3" d="M9 10V6a3 3 0 0 1 3-3v0a3 3 0 0 1 3 3v4m3-2 .917 11.923A1 1 0 0 1 17.92 21H6.08a1 1 0 0 1-.997-1.077L6 8h12Z"/>
                  </svg>
                </RouterLink>
            </div>
        </nav>
        
  </template>


<script>
export default {
    name: 'NavBar',
    data(){
    return{
        navShow: false,
        }
    },
methods: {
    changeNav(){
        if(!this.navShow){
            document.getElementById("mobileNavBar").style.display = "block";
            document.getElementById("mobImg").style.opacity = "0.5";
            this.navShow = !this.navShow;
        }else{
            document.getElementById("mobileNavBar").style.display = "none";
            document.getElementById("mobImg").style.opacity = "1";
            this.navShow = !this.navShow;
        }
    }
}

  }
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.lato-regular {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
}  

.lato-bold {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
}

#s-icon-mbile{
    display: flex;
    justify-content: center;
}

#s-icon-mbile svg{
    padding-left: 5px;
}

.carousel-sz{
    width: 45vw;
    height: calc(45vw * 0,777);
}

.mobileNavLinks{
    display: none;
    width: 100%;
    position:absolute;
    top:84px;
    text-align: center;
    background-color: whitesmoke;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 10;
    border-bottom: solid 3px rgb(158, 158, 158);
}

.mobileNavLinks a{
    width: 100%;
    color: black;
    text-decoration:none;
    transition: 0.2s;
    position: relative;
    display: inline-block;
    padding-top:16px;
    padding-bottom: 16px;
    margin-bottom: 2px;
}

.mobileNavLinks a:hover{
    background-color: rgb(160, 160, 160);
}

.carousel-pos{
    margin-left: 5%;
    padding-top: 3%;
    margin-bottom: 5%;
}

.tea-color{
    color: olivedrab;
}

#main-page-holder{
    width: 100%;
    color: rgb(25, 25, 25);
    display: flex;
    background-size: cover;
}

#shopping-icon{
    position: relative;
    float: right;
    margin-right: calc(10px + 3%);
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;
    display: block;
}

nav{
    width: 100%;
    background-color: whitesmoke;
    height: 85px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.nav-a-holder{
    margin-left: 7%;
    width:100%;
    height:65px;
}

.nav-a-holder a{
    padding-left: 2rem;
    padding-right: 2rem;
    text-decoration: none;
    color: black;
    height: 50px;
    display: inline-block;
    padding-top: 25px;
    transition: 0.2s;
}

.hide-sortiment{
    display: none;
}

.important-icon-sz{
    font-size: 1.7rem;
}

.under-ani::after{
    position: relative;
    display: inline-block;
    content: '';
   width: 100%;
   height: 2.5px;
   transform: scaleX(0);
   background: rgb(49, 49, 49);
   bottom: 12px;
   left: 0;
   transform-origin: bottom left;
   transition: transform 0.2s ease-out;
}

.under-ani:hover::after{
    transform-origin: bottom right;
    transform: scaleX(1);
}

.logo{
    margin-left: 2rem;
    width: auto;
    height: 100%;
    border-radius: 50px;
}

.sortiment-a:hover + .hide-sortiment, .nav-show-menu:hover{
    display: block;
    color: black;
}

.nav-a-holder a:hover{
    color: rgba(0, 0, 0, 0.65);
}

.display-product-holder{
    width: 215px;
    padding-top: 25px;
}

.display-product-holder:hover{
    background-color: rgba(0, 0, 0, 0.15);
}

.nav-show-menu{
    position: absolute;
    left: 0;
    top:84px;
    width: 100%;
    background-color: rgb(190, 190, 190);
    z-index: 2;
    border-bottom: 4px solid rgba(151, 151, 151, 0.5);
    justify-content: center;
}

.bor-u-65{
    border-bottom: 2px solid rgba(128, 128, 128, 0.5);
}

.mobileNav{
    display: none;
}

.mobileNav img{
    margin-right: 15px;
}

@media screen and (max-width: 900px) {
    .nav-a-holder{
        display: none;
    }
    .mobileNav{
        width: 100%;
        display:flex;
        justify-content: flex-end;
 
    }
}

@media screen and (max-width: 600px) {
    .logo{
    margin-left: 0.5rem;
    width: auto;
    height: 80%;
    border-radius: 50px;
    display: flex;
    align-items: center;
}
}
  </style>