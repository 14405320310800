<template>
    <div id="mainHolder">
        <h1 id="Heading">Časté dotazy</h1>
        <div class="question">
            <input id="q1" type="checkbox">
            <label for="q1">Za jak dlouho dorazí moje zásilka?</label>
            <div id="expandable">
                Veškeré objednávky by k Vám měly přijít do 1 pracovního týdne
            </div>
        </div>
        <div class="question">
            <input id="q2" type="checkbox">
            <label for="q2">Jsou vaše produkty bio a bez chemickálií?</label>
            <div id="expandable">
                Ano, většina našich čajů pochází z organických plantáží, 
                kde se nepoužívají žádné chemické hnojiva ani pesticidy. Pečlivě vybíráme dodavatele,
                 kteří splňují přísné ekologické standardy.
            </div>
        </div>
        <div class="question">
            <input id="q3" type="checkbox">
            <label for="q3">Jak vaše produkty skladovat, aby si udržely svoje aroma?</label>
            <div id="expandable">
                Doporučujeme skladovat čaj v suchu a temnu, ideálně v uzavřené nádobě, 
                která chrání čaj před světlem, vlhkostí a cizími pachy. 
                Čaje by měly být skladovány při pokojové teplotě, daleko od přímých zdrojů tepla.
            </div>
        </div>
        <div class="question">
            <input id="q4" type="checkbox">
            <label for="q4">Obsahují gruzínské produkty kofein?</label>
            <div id="expandable">
                Ano, gruzínské černé a zelené čaje obsahují kofein, 
                i když obecně méně než například káva. Množství kofeinu závisí na druhu čaje 
                a době louhování – delší louhování může zvýšit obsah kofeinu.
            </div>
        </div>
        <div class="question">
            <input id="q5" type="checkbox">
            <label for="q5">Jaké platby akceptujete a máte možnost dobírky?</label>
            <div id="expandable">
                Přijímáme platby kartou, bankovním převodem a na dobírku. 
                U dobírky je možné platit hotově nebo kartou při převzetí balíčku od kurýra.
            </div>
        </div>
        <div class="question">
            <input id="q6" type="checkbox">
            <label for="q6">Možnosti dárkového balení?</label>
            <div id="expandable">
                Rádi vám čaje zabalíme do krásného dárkového balení. 
                Stačí vybrat tuto možnost při dokončování objednávky nebo nám napsat do poznámky. 
                Dárkové balení je ideální způsob, jak obdarovat blízké kvalitním čajem v elegantním provedení.
            </div>
        </div>
        <div class="question">
            <input id="q7" type="checkbox">
            <label for="q7">Dostupnost limitovaných edicí a sezónních produktu?</label>
            <div id="expandable">
                Některé naše produkty jsou sezónní nebo v limitovaných edicích, 
                které se rychle vyprodají. Doporučujeme sledovat naše stránky a 
                sociální sítě, kde informujeme o nových limitovaných produktech, 
                aby vám neunikla příležitost si je pořídit.
            </div>
        </div>

        <div id="shoutoutbubble">
            <h3>Nenašli jste odpověď, kterou jste hledali? Ozvi se nám na náš mail <a href="mailto:g-caje@gmail.com">g-caje@gmail.com</a> a my ti tvůj dotaz zodpovíme!</h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AkceSite',
}

</script>

<style scoped>

@font-face {
        font-family: 'SansitaOne';
        src: URL('@/assets/SansitaOne.ttf') format('truetype');
    }
    @font-face {
        font-family: 'FugazOne';
        src: URL('@/assets/FugazOne.ttf') format('truetype');
    }
    @font-face {
        font-family: 'ReadexPro';
        src: URL('@/assets/ReadexPro.ttf') format('truetype');
    }

    #Heading{
        font-size: 2.3rem;
        font-family: 'SansitaOne';
    }
    #mainHolder{
        width: calc(100% - (80px + 2%));
        padding-left: calc(40px + 1%);
        padding-right: calc(40px + 1%);
        padding-bottom: 40px;
    }

    .question{
        width: 100%;
        transition:all 500ms ease;
    }
    .question input{
        appearance: none;
    }
    label{
        font-family: 'FugazOne';
        display: block;
        width: 100%;
        cursor: pointer;
        background-color: rgba(0, 128, 0, 0.4);
        border-radius: 4px;
        padding: 10px;
        font-size: 1.5rem;
        font-weight: 500;
    }

    .question input:checked ~ #expandable{
        display: block;
    }

    .question input:checked ~ label{
        background-color: rgba(0, 128, 0, 0.7);
    }

    #expandable{
        padding-left: 8px;
        padding-top: 16px;
        padding-bottom: 18px;
        display: none;
        font-size: 1.1rem;
        font-weight: 400;
        font-family: "ReadexPro";
    }

    #shoutoutbubble{
        width: 100%;
        margin-top: calc(60px + 1%);
        font-family: "ReadexPro";
        background-color: rgba(143, 129, 0, 0.3);
        border-radius: 4px;
        padding: 4px 10px 4px 10px;
        text-align: center;
    }

    #shoutoutbubble a {
        color: olivedrab;
        text-decoration: underline;
    }

    @media screen and (max-width: 800px) {
        label{
        padding: 6px;
        font-size: 1.2rem;
        }
        #expandable{
        padding-left: 6px;
        padding-top: 10px;
        padding-bottom: 12px;
        display: none;
        font-size: 0.9rem;
        font-weight: 400;
        font-family: "ReadexPro";
        }
        #shoutoutbubble{
            font-size: 0.8rem;
            padding: 4px 6px 6px 10px;
        }
        #Heading{
            font-size: 1.9rem;
        }
    }
    @media screen and (max-width: 500px) {
        label{
        padding: 4px;
        font-size: 1rem;
        width: auto;
        }
        #mainHolder{
        width: calc(100% - (20px + 2%));
        padding-left: calc(10px + 1%);
        padding-right: calc(10px + 1%);
        padding-bottom: 40px;
    }
        #shoutoutbubble{
            font-size: 0.8rem;
            padding: 4px 4px 4px 4px;
            width: auto;
        }
        #Heading{
            font-size: 1.7rem;
        }
    }

</style>
